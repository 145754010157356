import { Injectable } from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {User} from '../../../models/user/user';
import {UserTableInfo} from '../../../models/user/user-table-info';
import {UsersSummary} from '../../../models/user/users-summary';
import {Purchase} from '../models/purchase';
import {PurchaseTableInfo} from '../models/purchaseTableInfo';
import {PurchaseSummary} from '../models/purchaseSummary';
import {DashboardUsers} from '../models/dashboardUsers';
import {DashboardCoaches} from '../models/dashboardCoaches';
import {DashboardCashFlow} from '../models/dashboardCashFlow';
import {DashboardPartners} from '../models/dashboardPartners';
import {CoachPayment} from '../models/coachPayment';
import {PartnerPayment} from '../models/partnerPayment';
import {PartnerReferral} from '../models/partnerReferral';
import {DashboardCommission} from '../../../models/dashboardCommission';
import {DashboardVisitUser} from '../models/dashboard-visit-user';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private apiService: ApiService) {}

  usersData: Observable<{users: User[], info: UserTableInfo, summary: UsersSummary}>;

  sortType$ = new BehaviorSubject<number>(null);

  sortByTotal$ = new BehaviorSubject<string>(null);

  email = '';

  getUsers( count: number = 10, elementId: number = null, numberPage: number = null,
            email: string = '', sortType?: number, sortTotal?: string, schoolId?: number):
    Observable<{users: User[], info: UserTableInfo, summary: UsersSummary}> {
    this.email = email;
    this.usersData = this.apiService.getUsers(count, elementId, numberPage, email, sortType, sortTotal, schoolId);

    return this.usersData;
  }

  getUserById(userId: number): Observable<User> {

    if (this.usersData) {
      return this.usersData.pipe(
        map((data) => data.users.find(user => user.id === +userId))
      );
    } else {
      return throwError('');
    }
  }

  getPurchases( count: number = 10, elementId: number = null, numberPage: number = null):
    Observable<{purchases: Purchase[], info: PurchaseTableInfo, summary: PurchaseSummary}> {
    return this.apiService.getPurchases(count, elementId, numberPage);
  }

  getDashboardStat():
    Observable<{users: DashboardUsers, coaches: DashboardCoaches, cashFlow: DashboardCashFlow,
      partners: DashboardPartners, commissions: DashboardCommission[], visits: DashboardVisitUser}> {
    return this.apiService.getDashboardStat();
  }

  getCoaches():
      Observable<{coaches: DashboardCoaches[]}> {
    return this.apiService.getCoaches();
  }

  getCoach(coachId: number):
      Observable<{coach: DashboardCoaches, payments: CoachPayment[]}> {
    return this.apiService.getCoachById(coachId);
  }


  getPartners():
    Observable<{partners: DashboardPartners[]}> {
    return this.apiService.getPartners();
  }

  getPartner(partnerId: number):
    Observable<{partner: DashboardPartners, payments: PartnerPayment[], referrals: PartnerReferral[]}> {
    return this.apiService.getPartnerById(partnerId);
  }

  getCurrentEmail() {
    return this.email;
  }

}
